<template>
  <el-dialog
    :title="title"
    v-model="visible"
    width="800"
    draggable
    :destroy-on-close="true"
    @closed="onClosed"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140">
      <el-form-item label="公司名称" prop="companyName">
        <el-col :span="16">
          <el-input v-model="form.companyName" placeholder=""></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="简称" prop="nickname">
        <el-col :span="12">
          <el-input v-model="form.nickname" placeholder=""></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="法人姓名">
        <el-col :span="10">
          <el-input v-model="form.personName" placeholder=""></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="法人电话">
        <el-col :span="10">
          <el-input v-model="form.personMobile" placeholder=""></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="监管浏览手机号">
        <el-checkbox v-model="form.isRegulatoryShow"></el-checkbox>
        <div class="help-block">勾选则公司主账号可以查看监管报备客户手机号</div>
      </el-form-item>

      <el-form-item label="专员">
        <div class="flex-column">
          <choose-consumer
            text="选择专员"
            @success="consumerSelectionChange"
            :list="model.counselorList"
          ></choose-consumer>
          <!-- <div class="selection_wrp">
            <el-tag
              style="margin: 0 10px 10px 0"
              type="info"
              v-for="item in selection_consumer_list"
              :key="item.id"
              >{{ item.name }} {{ item.mobile }}</el-tag
            >
          </div> -->
        </div>
      </el-form-item>
      <div v-if="!form.id">
        <el-divider>主账号设定</el-divider>
        <el-form-item label="姓名" prop="adminName">
          <el-col :span="10">
            <el-input v-model="form.adminName" placeholder=""></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="手机号码" prop="adminMobile">
          <el-col :span="10">
            <el-input v-model="form.adminMobile" placeholder=""></el-input>
          </el-col>
        </el-form-item>
      </div>

      <el-divider>证件资料</el-divider>
      <el-form-item label="">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-around;
          "
        >
          <div style="margin-right: 10px">
            <upload-single
              ak="licenseImgUrl"
              @change="onUploadChange"
              :src="form.licenseImgUrl"
            ></upload-single>
            <p style="text-align: center">印业执照</p>
          </div>
          <div style="margin-right: 10px">
            <upload-single
              ak="kaihuImgUrl"
              :src="form.kaihuImgUrl"
              @change="onUploadChange"
            ></upload-single>
            <p style="text-align: center">开户行</p>
          </div>
          <div style="margin-right: 10px">
            <upload-single
              ak="idCardZhengImgUrl"
              :src="form.idCardZhengImgUrl"
              @change="onUploadChange"
            ></upload-single>
            <p style="text-align: center">法人身份证人脸面</p>
          </div>
          <div>
            <upload-single
              ak="idCardFanImgUrl"
              :src="form.idCardFanImgUrl"
              @change="onUploadChange"
            ></upload-single>
            <p style="text-align: center">法人身份证国徽面</p>
          </div>
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="visible = false" :disabled="saving">取消</el-button>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import UploadSingle from "../component/upload_single.vue";
import ChooseConsumer from "../component/choose_consumer.vue";
export default {
  components: {
    UploadSingle,
    ChooseConsumer,
  },
  data() {
    return {
      visible: false,
      saving: false,
      title: "",
      form: {},
      rules: {
        companyName: [
          {
            required: true,
            message: "请输入公司名称",
            trigger: "blur",
          },
        ],
        nickname: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
      },
      model: {},
      selection_consumer_list: [],
    };
  },
  props: {
    company: Object,
  },
  emits: ["success", "closed"],
  watch: {
    company: {
      handler() {
        this.title = "新增公司";
        this.form = {};
        if (this.company) {
          if (this.company.id > 0) {
            this.form.id = this.company.id;
            this.loadDtl();

            // this.form.companyName = this.company.companyName;
            // this.form.personName = this.company.personName;
            // this.form.personMobile = this.company.personMobile;
            // this.form.licenseImgUrl = this.company.licenseImgUrl;
            // this.form.kaihuImgUrl = this.company.kaihuImgUrl;
            // this.form.idCardZhengImgUrl = this.company.idCardZhengImgUrl;
            // this.form.idCardFanImgUrl = this.company.idCardFanImgUrl;

            this.title = "修改公司";
          } else {
            this.rules.adminName = [
              {
                required: true,
                message: "请输入姓名",
                trigger: "blur",
              },
            ];
            this.rules.adminMobile = [
              {
                required: true,
                message: "请输入手机号",
                trigger: "blur",
              },
            ];
          }
          this.visible = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     *
     */
    loadDtl() {
      this.loading = true;
      this.$http
        .get("admin/v1/company/dtl?id=" + this.company.id)
        .then((res) => {
          if (res.code == 0) {
            this.model = res.data;
            this.form.companyName = res.data.companyName;
            this.form.personName = res.data.personName;
            this.form.personMobile = res.data.personMobile;
            this.form.licenseImgUrl = res.data.licenseImgUrl;
            this.form.kaihuImgUrl = res.data.kaihuImgUrl;
            this.form.idCardZhengImgUrl = res.data.idCardZhengImgUrl;
            this.form.idCardFanImgUrl = res.data.idCardFanImgUrl;
            this.form.isRegulatoryShow = res.data.isRegulatoryShow;
            this.form.counselorIds = res.data.counselorIds;
            this.form.nickname = res.data.nickname;
          }
        })
        .finally(() => (this.loading = false));
    },

    /**
     *
     */
    onClosed() {
      this.$emit("closed");
    },

    /**
     * 上传图片成功回调
     * @param {*} url
     * @param {*} ak
     */
    onUploadChange(url, ak) {
      this.form[ak] = url;
      console.log(this.form);
    },

    /**
     * 选择专员回调
     * @param {*} list
     */
    consumerSelectionChange(list) {
      this.selection_consumer_list = list;
    },

    /**
     * 提交
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          //
          this.saving = true;
          this.form.counselorIds=[];
          if (
            this.selection_consumer_list &&
            this.selection_consumer_list.length > 0
          ) {
            this.form.counselorIds = this.selection_consumer_list.map(
              (x) => x.id
            );
          }
          this.$http
            .post("admin/v1/company/edit", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.$emit("success");
                this.visible = false;
              }
            })
            .finally(() => (this.saving = false));
        }
      });
    },
  },
};
</script>

<style scoped></style>
